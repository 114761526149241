import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "@StarberryUtils";
import { GetURL } from "../../common/site/functions";
import useDeviceMedia from "../../utils/useDeviceMedia";
import "./FooterMenu.scss";

const FooterMenu = ({ menus }) => {
  const { isCustomMax } = useDeviceMedia({ max: "991.98px" });
  const [openMenu, setOpenMenu] = useState({});

  const toggleSubMenu = (index) => {
    setOpenMenu((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <React.Fragment>      
      <Row className="mx-0 footer-links">
      {menus &&
        menus.Add_Menu.map((parentMenu, index) => {
          const isOpen = openMenu[index] || false;

          return (
            <Col lg={3} sm={12} key={index} className="px-0 menu-col">
              <ul className="menu-bar">
                <h6
                  onClick={() => isCustomMax && toggleSubMenu(index)}
                  className={`${isCustomMax ? "toggler" : ""} text-md-bold `}
                  style={{ cursor: isCustomMax ? "pointer" : "default" }}
                >
                  {parentMenu.Label}
                  {isCustomMax && (
                    <i className={isOpen ? "icon-minus" : "icon-plus"}></i>
                  )}
                </h6>
                {(isCustomMax ? isOpen : true) &&
                  parentMenu.Sub_Menus.map((subMenu, subIndex) => {
                    let url = "#";
                    if (subMenu.Link) {
                      url = `/${GetURL(subMenu.Link.id)}`;
                    }
                    return (
                      <li key={subIndex}>
                        <Link href={url} className="text-sm">{subMenu.Label}</Link>
                      </li>
                    );
                  })}
              </ul>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default FooterMenu;